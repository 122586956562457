input, select {
  margin-bottom: 8px;
  padding: 4px 0px;
  border-radius: 4px 4px 0 0;
  border-style: none none solid;
  border-width: 0 0 2px;
  border-color: var(--dark-grey);
  background-color: transparent;
  font-family: "Lato", sans-serif;
  color: var(--dark-grey);
  font-size: 1.25rem;
}
input:focus, select:focus {
  border-color: var(--peppermint);
  outline: 0;
}
input[disabled], select[disabled] {
  background-color: var(--bluesteel);
}

select {
  color: var(--peppermint);
}
select.modified {
  color: var(--dark-grey);
}
select option {
  color: var(--dark-grey);
}
select option:not([disabled]) {
  color: var(--dark-grey);
}
/*# sourceMappingURL=common.4e1676e3.css.map */
